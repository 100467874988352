import { defineStore } from "pinia";
import type { IomodelsVestiging } from "@/lib/backend/reserveer.api";
import { LS_LOCATION } from "@/lib/constants/localstorage";
import { DEFAULT_LOCATION, DEFAULT_LOCATION_ID, DEFAULT_LOCATION_NAME } from "@/lib/constants/misc";
import { CoachService } from "@/services/coach";

type State = {
	initialized: boolean;
	locationSlug: IomodelsVestiging["slug"];
	locations: IomodelsVestiging[];
};

const coachService = new CoachService();

export const useLocationStore = defineStore("location", {
	state: (): State => ({
		initialized: false,
		locationSlug: localStorage.getItem(LS_LOCATION) as LocationSlug,
		locations: [],
	}),
	getters: {
		location: ({ locations, locationSlug }) => {
			const foundLocation = locations.find((location) => location.slug === locationSlug);

			if (foundLocation) {
				return foundLocation;
			} else {
				return {
					id: DEFAULT_LOCATION_ID,
					slug: DEFAULT_LOCATION,
					naam: DEFAULT_LOCATION_NAME,
					zalen: [],
					afbeeldingen: [],
				};
			}
		},
		locationById:
			({ locations }) =>
			(id: number) =>
				locations.find((location) => location.id === id),
		locationByName: (state) => (name: string) =>
			state.locations.find((location) => location.naam === name),
		locationBySlug: (state) => (slug?: string | null) =>
			state.locations.find((location) => location.slug === slug),
		locationsSortedByName: ({ locations }) =>
			locations.sort(({ slug: slugA }, { slug: slugB }) => slugA.localeCompare(slugB)),
	},
	actions: {
		async init() {
			if (this.initialized) {
				return;
			}

			this.initialized = true;

			await this.getLocations();
			this.getPreference();
		},
		async getLocations() {
			const response = await coachService.getVestigingen();

			switch (response.status) {
				case 200: {
					this.locations = response.data;

					return this.locations;
				}

				default: {
					throw response;
				}
			}
		},
		getPreference() {
			const location = this.locationBySlug(localStorage.getItem(LS_LOCATION));
			if (!location) {
				return void (this.locationSlug = DEFAULT_LOCATION);
			}
			this.locationSlug = location.slug;
		},
		set(id: number) {
			const foundLocation = this.locationById(id);

			if (!foundLocation) {
				return;
			}

			this.locationSlug = foundLocation.slug;
			localStorage.setItem(LS_LOCATION, foundLocation.slug);
		},
	},
});

export default useLocationStore;
